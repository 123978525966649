
html, body {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	border: 0;
	font-family: 'Roboto', sans-serif;
}


select {
    background-color: #802502;
    color: white;
    font-size: 1.2em;
    width: 200px;
    height: 40px;
    padding-left: 75px;
    padding-bottom: 5px;
    border: none;
    border-radius: 5px;
}

select:hover {
    background-color: #a93b11;
}

select:focus {
    background-color: #802502;
}

#chord-type-select {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 10px;
}


.main-container {
    margin: 40px auto 0 auto;
    display: flex;
    width: 1400px;
    height: 500px;
    overflow: hidden;
    border-radius: 10px;
}

.white-key-1 {
    width: 100px;
    height: 100%;
    background-color: white;
    box-shadow: inset 0 0 5px #000000;
}

.black-key {
    position: relative;
    z-index: 100;
    width: 60px;
    height: 70%;
    background-color: black;
    box-shadow: inset 0 0 5px white, 0 0 5px black;
    border-radius: 0 0 10px 10px;
}

.white-key {
    position: relative;
    z-index: 99;
    width: 100px;
    height: 100%;
    background-color: white;
    box-shadow: inset 0 0 5px #000000;
}

.margin-left {
    margin-left: -30px;
}

.active-note {
    display: flex;
    flex-direction: column-reverse;
    background-color: #90100a;
    padding-bottom: 40px;
    font-size: 1.4em;
    text-align: center;
    color: white;
}



@media only screen and (max-width: 1430px) {
    .App .main-container {
        width: 700px;
        flex-wrap: wrap;
    }
}

@media only screen and (max-width: 740px) {
    .App .main-container {
        width: 350px;
        height: 250px;
    }

    .white-key-1 {
        width: 50px;
    }

    .white-key-1, .white-key {
        width: 50px;
    }

    .black-key {
        width: 30px;
    }

    .margin-left {
        margin-left: -15px;
    }

    .active-note {
        font-size: 1em;
    }

    select {
        width: 100px;
        height: 30px;
        padding-left: 30px;
        font-size: 1em;
    }
}
