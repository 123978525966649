@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto+Slab&display=swap');
@import url('https://fonts.googleapis.com/css?family=PT+Sans&display=swap');

* {
  font-family: 'PT Sans', arial, 'sans-serif';
  box-sizing: border-box;
}


.main-nav {
  display: flex;
  justify-content: space-evenly;
  margin: 0;
  width: 100%;
  height: 35px;
  background-color: #6d3b1d;
}

.main-nav a {
  padding-top: 6px;
  text-decoration: none;
  width: 50%;
  text-align: center;
  color: white;
}

.main-nav a:hover {
  background-color: #4a0b0b;
}




h1 {
  margin-top: 13px;
  text-align: center;
  color: #f7efd8;
}

body {
  margin: 0;
  background-color: rgb(21, 7, 0);
}

.nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 90%;
  max-width: 1000px;
  border-radius: 5px;
  margin: 15px auto;
  padding: 25px;
  background-color: #330a0a;
  background: rgb(51, 10, 10);
  background: linear-gradient(
    90deg,
    rgba(51, 10, 10, 1) 0%,
    rgb(80, 22, 22) 50%,
    rgba(51, 10, 10, 1) 100%
  );
}

.key-buttons-wrapper {
  display: flex;
  justify-content: center;
  width: 400px;
  flex-wrap: wrap;
}

.modifier-buttons-wrapper {
  width: 200px;
}

.key-selector {
  display: inline-block;
  width: 75px;
  height: 75px;
  margin: 0 5px 0 5px;
}

.key-modifier {
  display: inline-block;
  width: 75px;
  height: 75px;
  margin: 5px;
}

.key-button {
  height: 100%;
  width: 100%;
  border: none;
  background-color: #f7efd8;
  border-radius: 50%;
  font-size: 1.75em;
}

.key-button:hover {
  background-color: #583b2d;
  color: white;
}

.key-button:focus {
  outline: none;
}

.scales-wrapper {
  border-radius: 5px;
  padding: 10px;
  max-width: 1000px;
  margin: 0 auto;
  background-color: #583b2d;
  color: black;
  background: rgb(71, 44, 32);
  background: linear-gradient(
    90deg,
    rgba(71, 44, 32, 1) 0%,
    rgba(120, 71, 48, 1) 50%,
    rgba(71, 44, 32, 1) 100%
  );
}

.modes-wrapper {
  margin: 10px;
  height: 75px;
  display: flex;
  justify-content: center;
  font-size: 1.7em;
}

.scale-note {
  display: inline;
}

.scale-notes {
  border-radius: 3px;
  background-color: #f7efd8;
  display: flex;
  align-items: center;
  height: 100%;
}

.mode-name {
  background-color: #f5a270;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 200px;
  margin: 10px;
  border-radius: 3px;
  overflow: hidden;
}

.note-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 75px;
  text-align: center;
}

.numeral {
  font-family: 'Roboto Slab', serif;
  width: 100%;
  height: 30px;
  font-size: 0.7em;
}

.scale-note {
  width: 100%;
  font-size: 1.1em;
}

.footer {
  background-color: #330a0a;
  margin-top: 400px;
  height: 50px;
  width: 100%;
}

@media (max-width: 860px) {
  .nav {
    border-radius: 0px;

  }

  .scales-wrapper {
    flex-direction: column;
  }

  .modes-wrapper {
    flex-direction: column;
    height: auto;
  }

  .mode-name {
    margin: 0 auto 10px auto;
    width: 100%;
  }

  .key-selector {
    margin-bottom: 10px;
    width: 40px;
    height: 40px;
  }

  .key-modifier {
    width: 40px;
    height: 40px;
  }

  .key-button {
    font-size: 1.4em;
  }

  .scales-wrapper {
    border-radius: 0;
  }

  .scale-notes {
    justify-content: center;
    max-width: 650px;
  }

  .scale-note {
    font-size: 0.8em;
  }
}
